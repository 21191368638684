import React from 'react'

import { useLazyQuery } from '@apollo/client'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { IconButtonProps, Tooltip } from '@mui/material'
import dayjs from 'dayjs'
import {
  type EventTypeFilter,
  GetAuditTrailCSV,
  GetAuditTrailCSVVariables,
} from 'types/graphql'

import IconButton from 'src/components/IconButton/IconButton'
import useAnalytics from 'src/lib/hooks/useAnalytics'
import { useAuth } from 'web/src/Providers'
interface Props extends IconButtonProps {
  eventTypes?: EventTypeFilter[]
  userIds?: number[]
  from?: string
  to?: string
  query?: string
}
const CSVQuery = gql`
  query GetAuditTrailCSV(
    $from: String
    $to: String
    $userIds: [Int!]
    $query: String
    $eventTypes: [EventTypeFilter!]
  ) {
    downloadAuditLogCSV(
      from: $from
      to: $to
      userIds: $userIds
      query: $query
      eventTypes: $eventTypes
    )
  }
`
export default function CSVDownloadButton({
  query,
  from,
  to,
  userIds,
  eventTypes,
  ...iconButtonProps
}: Props): React.ReactElement {
  const { currentUser } = useAuth()
  const { trackEvent } = useAnalytics()
  const [getCSV, { loading }] = useLazyQuery<
    GetAuditTrailCSV,
    GetAuditTrailCSVVariables
  >(CSVQuery, {
    variables: {
      query,
      from,
      to,
      userIds,
      eventTypes,
    },
  })
  const handleDownload = async () => {
    const { data } = await getCSV()
    if (data?.downloadAuditLogCSV) {
      const blob = new Blob([data.downloadAuditLogCSV], { type: 'text/csv' })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = `${dayjs().format('YYYY-MM-DD')}_${currentUser.parentData.name}_The_Hub_Audit_Logs.csv`
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      window.URL.revokeObjectURL(url)
    }
    trackEvent('Audit Log', 'Clicked Download CSV button')
  }
  return (
    <IconButton
      size={10}
      onClick={handleDownload}
      loading={loading}
      {...iconButtonProps}
    >
      {/* TODO Tooltip not functioning when wrapping IconButton */}
      <Tooltip title={'Download these results to a CSV'}>
        <FileDownloadOutlinedIcon data-intercom-target="csv-download-button" />
      </Tooltip>
    </IconButton>
  )
}
