import React, { useEffect, useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import { Grid2 as Grid } from '@mui/material'
import { LoginToBaserowQuery } from 'types/graphql'

import useAuditLogStore from 'src/components/AuditLog/store'
import { getFieldsForTable } from 'src/components/HubDash/lib/baserow/baserowApi'

import { AuditLogNode } from '../AuditLogTable'

import {
  transformBaserowUpdateData,
  transformHubsUpdateData,
  transformHubsData,
} from './DataTransformers'
import ExpandedRowList, { ListItem } from './ExpandedRowList'
import ExpandedRowLoading from './ExpandedRowLoading'

const GET_BASEROW_TOKEN = gql`
  query LoginToBaserowQuery {
    loginToBaserow {
      jwt
    }
  }
`

interface Props {
  AuditLog: AuditLogNode
}

export default function ExpandedRow({
  AuditLog: { newValues, oldValues, actionType, source, shortSummary },
}: Props): React.ReactElement {
  const [
    token,
    setToken,
    fields,
    setFields,
    tableIdsSearched,
    setTableIdsSearched,
  ] = useAuditLogStore((state) => [
    state.token,
    state.setToken,
    state.fields,
    state.setFields,
    state.tableIdsSearched,
    state.setTableIdsSearched,
  ])

  const [getBaserowToken] = useLazyQuery<LoginToBaserowQuery>(GET_BASEROW_TOKEN)
  const [isTokenLoading, setIsTokenLoading] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [previousValues, setPreviousValues] = useState<ListItem[]>([])
  const [updatedValues, setUpdatedValues] = useState<ListItem[]>([])

  const isActionTypeCreated = actionType === 'INSERT'
  const isActionTypeUpdated = actionType === 'UPDATE'

  useEffect(() => {
    const fetchBaserowToken = async () => {
      setIsTokenLoading(true)
      const { data } = await getBaserowToken()
      const _token = data?.loginToBaserow.jwt
      setToken(_token)
      setIsTokenLoading(false)
    }

    if (!token) fetchBaserowToken()
  }, [token, getBaserowToken, setToken, setIsTokenLoading])

  // Data Fetching & Transformation
  useEffect(() => {
    const fetchFieldNames = async () => {
      if (
        typeof oldValues === 'object' &&
        'table_id' in oldValues &&
        !tableIdsSearched.includes(oldValues.table_id.toString())
      ) {
        const response = await getFieldsForTable({
          token,
          tableId: oldValues.table_id,
        })
        const _fields = response.map((field) => ({
          id: field.id,
          name: field.name,
          order: field.order,
          tableId: oldValues.table_id,
        }))
        setTableIdsSearched([
          ...tableIdsSearched,
          oldValues.table_id.toString(),
        ])
        setFields([...fields, ..._fields])
        return _fields
      } else return fields
    }

    const handleDataTransformation = async (fields) => {
      if (source === 'BASEROW' && isActionTypeUpdated) {
        const prevData = await transformBaserowUpdateData(
          newValues,
          fields,
          token,
          true,
        )
        const newData = await transformBaserowUpdateData(
          newValues,
          fields,
          token,
          false,
        )
        setPreviousValues(prevData)
        setUpdatedValues(newData)
      }
    }

    if (token && !loading) {
      setLoading(true)
      fetchFieldNames().then((fields) => {
        handleDataTransformation(fields).then(() => {
          setLoading(false)
        })
      })
    }
  }, [
    token,
    fields,
    source,
    oldValues,
    newValues,
    shortSummary,
    isActionTypeUpdated,
    setLoading,
    tableIdsSearched,
    setTableIdsSearched,
  ])

  if (loading || isTokenLoading) return <ExpandedRowLoading />

  return (
    <Grid container className={'ml-1 p-10'} spacing={3}>
      {isActionTypeUpdated ? (
        <>
          <ExpandedRowList
            title={'Previous Values'}
            listItems={
              source === 'BASEROW'
                ? previousValues
                : transformHubsUpdateData(oldValues, newValues)
            }
          />
          <div className={'mr-10'} />
          <ExpandedRowList
            title={'Updated Values'}
            listItems={
              source === 'BASEROW'
                ? updatedValues
                : transformHubsUpdateData(newValues, oldValues)
            }
          />
        </>
      ) : (
        <ExpandedRowList
          title={`${isActionTypeCreated ? 'Created' : 'Deleted'} Values`}
          listItems={
            isActionTypeCreated
              ? transformHubsData(newValues)
              : transformHubsData(oldValues)
          }
        />
      )}
    </Grid>
  )
}
