import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import Typography from '@mui/material/Typography/Typography'
import { captureException } from '@sentry/browser'
import { Typography as AntDTypography } from 'antd'
import Table, { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import type {
  GetAuditLogsQuery,
  GetAuditLogsQueryVariables,
  AuditLogTableFilterMembershipQuery,
  AuditLogTableFilterMembershipQueryVariables,
  AuditLogEventTypesQueryVariables,
  AuditLogEventTypesQuery,
  EventType,
  EventTypeFilter,
} from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import { AUDIT_LOG_EVENT_TYPES_QUERY } from 'src/components/AuditLog/AuditLogTable/FilterQueries/EventTypesQuery'
import { AUDIT_LOG_TABLE_FILTER_MEMBERSHIP_QUERY } from 'src/components/AuditLog/AuditLogTable/FilterQueries/MembershipQuery'
import { AUDIT_LOG_TABLE_QUERY } from 'src/components/AuditLog/AuditLogTable/Query'
import MUIProgress from 'src/components/Library/Loading'
import useAnalytics from 'src/lib/hooks/useAnalytics'

import EventTypeDot from '../EventTypeDot'

import ExpandedRow from './ExpandedRow/ExpandedRow'
import LoadMore from './Extras/LoadMore'
import UserRenderCell from './Extras/UserRenderCell'

const { Paragraph } = AntDTypography

export type AuditLogNode = GetAuditLogsQuery['auditLogs']['nodes'][number]

// the field names here are derived from
// the dataIndex of the columns in the table
type Filters = {
  userIds: number[]
  eventTypes: number[]
}

export type MembershipUser =
  AuditLogTableFilterMembershipQuery['membershipsByClient'][0]['user']

// Global header offset (85) + Audit Log Header Options (80) + Intercom (40) + Table Footer (84) + Table Header (38)
const offset = 327
const errorAlertOffset = 64
const scrollHeight = window.innerHeight - offset

interface Props {
  isLiveFeed: boolean
  variables: GetAuditLogsQueryVariables
  setUserIds: Dispatch<SetStateAction<number[]>>
  setEventTypes: Dispatch<SetStateAction<EventTypeFilter[]>>
  setError: Dispatch<SetStateAction<string>>
}

export const AuditLogTable = ({
  isLiveFeed,
  variables: parentVariables,
  setUserIds,
  setEventTypes,
  setError,
}: Props) => {
  const { trackEvent } = useAnalytics()
  const [variables, setVariables] = useState<GetAuditLogsQueryVariables>({})
  const [userList, setUserList] = useState<MembershipUser[]>([])
  const [eventTypeList, setEventTypeList] = useState<EventType[]>([])

  const { data, loading, error, fetchMore, startPolling, stopPolling } =
    useQuery<GetAuditLogsQuery, GetAuditLogsQueryVariables>(
      AUDIT_LOG_TABLE_QUERY,
      { variables },
    )

  const getMoreResults = useCallback(async () => {
    await fetchMore({
      variables: { ...variables, after: data.auditLogs.pageInfo.endCursor },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        return {
          auditLogs: {
            ...fetchMoreResult.auditLogs,
            nodes: [
              ...prev.auditLogs.nodes,
              ...fetchMoreResult.auditLogs.nodes,
            ],
          },
        }
      },
    })
  }, [data, variables])

  useQuery<
    AuditLogTableFilterMembershipQuery,
    AuditLogTableFilterMembershipQueryVariables
  >(AUDIT_LOG_TABLE_FILTER_MEMBERSHIP_QUERY, {
    onCompleted: (data) =>
      setUserList(
        data.membershipsByClient.map((membership) => membership.user),
      ),
  })

  useQuery<AuditLogEventTypesQuery, AuditLogEventTypesQueryVariables>(
    AUDIT_LOG_EVENT_TYPES_QUERY,
    {
      onCompleted: (data) => setEventTypeList(data.auditLogEventTypes),
    },
  )

  // Update local variables from parent
  useEffect(() => {
    setVariables((prevVariables = {}) => ({
      ...prevVariables,
      ...parentVariables,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }))
  }, [parentVariables])

  // Controls polling updates
  useEffect(() => {
    if (isLiveFeed) {
      startPolling(15000)
    } else {
      stopPolling()
    }
  }, [isLiveFeed, startPolling, stopPolling])

  // Baserow Error handling
  useEffect(() => {
    if (data?.auditLogs.error) {
      setError(data?.auditLogs.error?.message)
    } else {
      setError(null)
    }
  }, [data?.auditLogs.error])

  useEffect(() => {
    // The audit log intercom tour requires us to set a data-intercom-target attribute
    // this is so intercom knows where to point the user
    // i.e. 'Click here to filter by performed by...'
    try {
      // wrap the whole thing in a try catch as we would rather the tour fail than the whole page
      const eventTypeColumn = Array.from(
        document.querySelectorAll('.ant-table-filter-column'),
      ).find((el) => el.textContent.includes('Event Type'))
      if (eventTypeColumn) {
        const eventTypeFilterButton = eventTypeColumn.querySelector(
          '.ant-dropdown-trigger',
        )
        if (eventTypeFilterButton) {
          eventTypeFilterButton.setAttribute(
            'data-intercom-target',
            'event-type-filter-button',
          )
        }
      }

      const performedByColumn = Array.from(
        document.querySelectorAll('.ant-table-filter-column'),
      ).find((el) => el.textContent.includes('Performed By'))
      if (performedByColumn) {
        const performedByFilterButton = performedByColumn.querySelector(
          '.ant-dropdown-trigger',
        )
        if (performedByFilterButton) {
          performedByFilterButton.setAttribute(
            'data-intercom-target',
            'performed-by-filter-button',
          )
        }
      }
    } catch (error) {
      const errorMessage = new Error(
        'Error setting data-intercom-target attributes. Audit log product tour likely to fail.',
      )

      captureException({
        errorMessage,
        error,
      })
    }
  }, [])

  const columns: ColumnsType<AuditLogNode> = useMemo(() => {
    return [
      {
        title: 'Timestamp',
        dataIndex: 'createdAt',
        render: (createdAt: AuditLogNode['createdAt']) => (
          <Typography variant={'body2'}>
            {dayjs(createdAt).format('ddd. DD MMM. YYYY h:mma')}
          </Typography>
        ),
      },
      {
        title: 'Event Type',
        dataIndex: 'eventTypes',
        filterOnClose: true,
        filterSearch: true,
        filters: eventTypeList.map((eventType) => ({
          text: eventType.name,
          value: eventType.id,
        })),
        render: (_, node: AuditLogNode) => (
          <div className={'flex items-center'}>
            <EventTypeDot summary={node.shortSummary} />
            <Paragraph
              style={{
                marginBottom: 0,
                fontFamily: 'sans-serif',
              }}
            >
              {node.shortSummary}
            </Paragraph>
          </div>
        ),
      },
      {
        title: 'Event',
        dataIndex: 'summary',
        render: (summary: AuditLogNode['summary']) => (
          <Paragraph
            ellipsis={{ expandable: true }}
            style={{
              marginBottom: 0,
              fontFamily: 'sans-serif',
            }}
          >
            {summary}
          </Paragraph>
        ),
      },
      {
        title: 'Performed By',
        dataIndex: 'userIds',
        filters: userList.map((user) => ({
          text: user.name,
          value: user.id,
        })),
        filterOnClose: true,
        filterSearch: true,
        render: (_, auditLogNode: AuditLogNode) => (
          <UserRenderCell OriginDetails={auditLogNode.OriginDetails} />
        ),
      },
    ]
  }, [userList, eventTypeList])

  return (
    <>
      <Table
        style={{
          minHeight: loading && (data?.auditLogs.error ? '70vh' : '80vh'),
        }}
        onChange={(_, filters: Filters) => {
          setUserIds(filters.userIds)
          setEventTypes(
            eventTypeList
              .filter(({ id }) => filters?.eventTypes?.includes(id))
              .map(({ __typename, id, ...rest }) => ({ ...rest })),
          )
        }}
        rowClassName={'audit-log-table-row-class'}
        loading={{
          spinning: loading,
          indicator: <MUIProgress />,
          style: { paddingTop: '35vh' },
        }}
        pagination={false}
        dataSource={data?.auditLogs?.nodes}
        columns={columns}
        scroll={{
          y: scrollHeight - (data?.auditLogs.error ? errorAlertOffset : 0),
          scrollToFirstRowOnChange: false,
          x: window.innerWidth < 1500 ? '100vw' : undefined,
        }}
        summary={() => {
          if (
            !data?.auditLogs?.nodes ||
            !data?.auditLogs?.pageInfo?.hasNextPage
          )
            return null
          return <LoadMore onLoadMore={getMoreResults} loading={loading} />
        }}
        size={'small'}
        rowKey={(node: AuditLogNode) => node.id}
        locale={{
          emptyText: error
            ? 'Something went wrong'
            : loading
              ? ''
              : 'No Results',
        }}
        expandable={{
          expandedRowRender: (auditLog: AuditLogNode) => (
            <ExpandedRow AuditLog={auditLog} />
          ),
          rowExpandable: (record) => !!record.oldValues || !!record.newValues,
          onExpandedRowsChange: (expandedKeys) =>
            trackEvent('Audit Log', 'Expanded Table Row', {
              expandedRowIds: expandedKeys.join(', '),
            }),
        }}
      />
      {data && (
        <div className={'flex justify-end p-4'}>
          <Typography variant={'body2'}>
            {`Showing ${data?.auditLogs.nodes.length.toLocaleString()} of ${data?.auditLogs?.pageInfo?.totalCount.toLocaleString()} results`}
          </Typography>
        </div>
      )}
    </>
  )
}
