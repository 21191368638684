import { BASEROW_FIELD_TYPES } from 'api/src/common/constants'
import { JsonValue } from 'type-fest'

import { Field } from 'src/components/AuditLog/store'
import { getRowById } from 'src/components/HubDash/lib/baserow/baserowApi'

import { ListItem } from './ExpandedRowList'

export function toFriendlyName(str) {
  return str
    .replace(/([A-Z])/g, ' $1')
    .trim()
    .replace(/_/g, ' ')
    .replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase(),
    )
    .replace(/\bId\b/g, 'ID')
}

export function transformHubsData(values: JsonValue): ListItem[] {
  return Object.keys(values)
    .map((key) => ({
      label: toFriendlyName(key),
      value: values[key],
    }))
    .sort((a, b) => a.label.localeCompare(b.label))
}

export function transformHubsUpdateData(
  primaryValues: JsonValue,
  comparisonValues: JsonValue,
): ListItem[] {
  return Object.keys(primaryValues)
    .map((key) => ({
      label: toFriendlyName(key),
      value: primaryValues[key],
      isValueHighlighted:
        JSON.stringify(primaryValues[key]) !==
        JSON.stringify(comparisonValues[key]),
    }))
    .sort((a, b) => a.label.localeCompare(b.label))
}

function parseRowFields({
  originalRow,
  updatedFieldsMetadata,
  fields,
}: {
  originalRow: JsonValue
  updatedFieldsMetadata: JsonValue
  fields: Field[]
}): ListItem[] {
  const listItems: ListItem[] = []
  fields.forEach((field) => {
    const fieldId = field.id
    const fieldName = field.name
    const fieldKey = `field_${fieldId}`
    const fieldMetadata = updatedFieldsMetadata[fieldKey]
    const fieldType = fieldMetadata?.type
    const rawValue = originalRow[fieldKey]
    let value = ''

    // Skip fields that are not present in the metadata or the original row
    if (!fieldMetadata || !Object.keys(originalRow).includes(fieldKey)) return

    // Field specific transformations
    switch (fieldType) {
      case BASEROW_FIELD_TYPES.LINK_ROW: {
        const ids = rawValue
        if (Array.isArray(ids) && ids.length > 0) {
          const linkedRows = fieldMetadata.linked_rows
          const names = ids.map((id) => linkedRows[id]?.value).filter(Boolean)
          value = names.join(', ')
        }
        break
      }
      case BASEROW_FIELD_TYPES.SINGLE_SELECT: {
        const option = fieldMetadata.select_options[rawValue]
        value = option?.value || ''
        break
      }
      case BASEROW_FIELD_TYPES.MULTIPLE_SELECT: {
        const options = fieldMetadata.select_options
        const selectedOptions = rawValue?.map((id) => options[id]?.value)
        value = selectedOptions?.join(', ')
        break
      }
      case BASEROW_FIELD_TYPES.DURATION: {
        const duration = rawValue
        if (typeof duration !== 'number' || isNaN(duration)) {
          value = ''
        } else {
          const hours = Math.floor(duration / 3600)
          const minutes = Math.floor((duration % 3600) / 60)
          const seconds = duration % 60
          value = `${hours}h ${minutes}m ${seconds}s`
        }
        break
      }
      case BASEROW_FIELD_TYPES.FILE: {
        const filesMeta = rawValue
        value = filesMeta?.map((fileMeta) => fileMeta.visible_name)?.join(', ')
        break
      }
      default:
        value = rawValue || ''
    }

    listItems.push({
      label: `${fieldName}`,
      value,
    })
  })
  return listItems
}

export const transformBaserowUpdateData = async (
  action_params: JsonValue,
  fields: Field[],
  token: string,
  isPreviousData: boolean,
): Promise<ListItem[]> => {
  let listItems: ListItem[] = []
  const rowIds = action_params['row_ids']
  const tableId = action_params['table_id']
  const primaryFieldId = fields.find(
    (field) => field.order === 0 && field.tableId === tableId,
  )?.id

  // If no row ids are provided by baserow, then just display all params that are provided
  if (!rowIds?.length) {
    return Object.keys(action_params)
      .map((key) => ({
        label: toFriendlyName(key),
        value: action_params[key],
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
  }

  let rowsToProcess: { rowId: number; originalRow: JsonValue }[]

  // the action_params object provided by baserow is slightly different depending on type
  if (isPreviousData) {
    rowsToProcess = rowIds.map((rowId) => ({
      rowId,
      originalRow: action_params['original_rows_values_by_id'][rowId],
    }))
  } else {
    rowsToProcess = action_params['row_values'].map((row) => ({
      rowId: row.id,
      originalRow: row,
    }))
  }

  await Promise.all(
    rowsToProcess.map(async ({ rowId, originalRow }) => {
      // To collect the row's primary field's value
      const rowMeta = await getRowById({
        token,
        tableId,
        rowId,
      })

      listItems.push({
        label: 'Primary Field:',
        value: rowMeta[`field_${primaryFieldId}`],
      })

      const updatedFieldsMetadata =
        action_params['updated_fields_metadata_by_row_id'][rowId]

      const parsedListItems = parseRowFields({
        originalRow,
        updatedFieldsMetadata,
        fields,
      })
      listItems = [...listItems, ...parsedListItems]
    }),
  )

  return listItems
}
