export const AUDIT_LOG_TABLE_QUERY = gql`
  query GetAuditLogsQuery(
    $after: String
    $from: String
    $to: String
    $timezone: String
    $userIds: [Int!]
    $query: String
    $eventTypes: [EventTypeFilter!]
  ) {
    auditLogs: auditLogs(
      after: $after
      to: $to
      from: $from
      timezone: $timezone
      userIds: $userIds
      query: $query
      eventTypes: $eventTypes
    ) {
      nodes {
        id
        source
        actionType
        tableName {
          name
          friendlyName
        }
        dbRole
        recordId
        createdAt
        formattedCreatedAt
        oldValues
        newValues
        userId
        summary
        shortSummary
        OriginDetails {
          User {
            id
            name
            avatarUrl
            email
          }
          generatedByAutomation
          generatedByDatabaseOperation
        }
      }
      pageInfo {
        totalCount
        hasNextPage
        endCursor
      }
      error {
        message
        error
        code
      }
    }
  }
`
