import { create } from 'zustand'

export interface Field {
  id: number
  name: string
  order: number
  tableId: number
}

interface AuditLogStore {
  token: string
  setToken: (token: string) => void
  fields: Field[]
  setFields: (fields: Field[]) => void
  tableIdsSearched: string[]
  setTableIdsSearched: (tableIdsSearched: string[]) => void
}

const useAuditLogStore = create<AuditLogStore>((set) => ({
  token: '',
  setToken: (token) => set({ token }),
  fields: [],
  setFields: (fields) => set({ fields }),
  tableIdsSearched: [],
  setTableIdsSearched: (tableIdsSearched) => set({ tableIdsSearched }),
}))

export default useAuditLogStore
