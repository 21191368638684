// This is the query that populates the dropdown for the Performed By filter in the Audit Log table.
export const AUDIT_LOG_TABLE_FILTER_MEMBERSHIP_QUERY = gql`
  query AuditLogTableFilterMembershipQuery {
    membershipsByClient {
      user {
        id
        name
      }
    }
  }
`
