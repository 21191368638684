import React, { Dispatch, SetStateAction } from 'react'

import { FormControl, FormControlLabel, Tooltip } from '@mui/material'
import Alert from '@mui/material/Alert'
import { Badge } from 'antd'
import dayjs from 'dayjs'
import type { EventTypeFilter } from 'types/graphql'

import Switch from 'src/components/Library/Switch/Switch'
import useAnalytics from 'src/lib/hooks/useAnalytics'

import DateRangePicker from '../Library/RangePicker/DateRangePicker'
import { SearchField } from '../Library/SearchField/SearchField'

import CSVDownloadButton from './CSVDownloadButton'

interface Props {
  isLiveFeed: boolean
  onLiveFeedChange: (isLiveFeed: boolean) => void
  query: string | undefined
  setQuery: Dispatch<SetStateAction<string>>
  startDate: string
  setStartDate: Dispatch<SetStateAction<string>>
  endDate: string
  setEndDate: Dispatch<SetStateAction<string>>
  userIds: number[]
  eventTypes: EventTypeFilter[]
  error: string | null
}

export default function HeaderOptions({
  isLiveFeed,
  onLiveFeedChange,
  query,
  setQuery,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  userIds,
  eventTypes,
  error,
}: Props) {
  const { trackEvent } = useAnalytics()

  return (
    <>
      <div className={'flex'}>
        <div
          data-testid="audit-log-main-search"
          className={'flex'}
          style={{ flexGrow: 1 }}
        >
          <SearchField
            debounced
            value={query}
            className={'pr-2'}
            onChange={(q) => {
              trackEvent('Audit Log', 'Searched', { query: q })
              setQuery(q)
            }}
            placeholder={'Search...'}
          />
        </div>
        <div className={'flex justify-end'}>
          <div className={'pr-2'}>
            <DateRangePicker
              startDate={startDate}
              setStartDate={(date) => {
                trackEvent('Audit Log', 'Changed Start Date Filter', { date })
                setStartDate(date)
              }}
              endDate={endDate}
              setEndDate={(date) => {
                trackEvent('Audit Log', 'Changed Start Date Filter', { date })
                setEndDate(date)
              }}
              presets={[
                {
                  label: 'Past Month',
                  value: [dayjs().subtract(1, 'month'), dayjs()],
                },
                {
                  label: 'Past Week',
                  value: [dayjs().subtract(1, 'week'), dayjs()],
                },
                {
                  label: 'Yesterday',
                  value: [
                    dayjs().subtract(1, 'day'),
                    dayjs().subtract(1, 'day'),
                  ],
                },
                {
                  label: 'Today',
                  value: [dayjs(), dayjs()],
                },
                {
                  label: 'This Month',
                  value: [dayjs().startOf('month'), dayjs().endOf('month')],
                },
              ]}
            />
          </div>
          <div className={'ml-2 flex'}>
            <CSVDownloadButton
              query={query}
              from={startDate}
              to={endDate}
              eventTypes={eventTypes}
              userIds={userIds}
            />
            <Tooltip title={'Toggle to see new data appear automatically'}>
              <FormControl className={'ml-2'}>
                <Badge
                  dot={isLiveFeed}
                  status={'processing'}
                  color={'#2e7d32'}
                  offset={[-3, 18]}
                >
                  <FormControlLabel
                    label={'Live Feed'}
                    control={
                      <Switch
                        defaultChecked={isLiveFeed}
                        color={'success'}
                        onChange={() => {
                          trackEvent('Audit Log', 'Toggled Live Feed', {
                            isLiveFeed: !isLiveFeed,
                          })
                          onLiveFeedChange(!isLiveFeed)
                        }}
                      />
                    }
                    data-intercom-target={'live-feed-toggle'}
                  />
                </Badge>
              </FormControl>
            </Tooltip>
          </div>
        </div>
      </div>
      {error && (
        <Alert className={'mt-4'} severity={'warning'}>
          {error}
        </Alert>
      )}
    </>
  )
}
