import React from 'react'

import { Table } from 'antd'

import Button from 'src/components/Library/Button'

interface Props {
  onLoadMore: () => void
  loading: boolean
}

export default function LoadMore({
  onLoadMore,
  loading,
}: Props): React.ReactElement {
  return (
    <Table.Summary.Row>
      <Table.Summary.Cell
        index={0}
        colSpan={5}
        className={'bg-[#FAFAFA] !p-3 text-center'}
      >
        <Button
          loading={loading}
          variant={'text'}
          onClick={() => onLoadMore()}
          size={'small'}
          style={{ width: 'auto' }}
          className={'px-3'}
          buttonDataTestId="load-more-button"
        >
          {loading ? 'Loading...' : 'Load more'}
        </Button>
      </Table.Summary.Cell>
    </Table.Summary.Row>
  )
}
