import React from 'react'

import { Grid2 as Grid, Skeleton } from '@mui/material'

export default function ExpandedRowLoading(): React.ReactElement {
  return (
    <Grid container className={'ml-1 p-10'} spacing={3}>
      <div className={'mr-[100px]'}>
        <Skeleton variant={'text'} sx={{ fontSize: '1rem', width: 200 }} />
        <Skeleton
          variant={'rounded'}
          width={200}
          height={150}
          className={'mt-2'}
        />
      </div>
      <div>
        <Skeleton variant={'text'} sx={{ fontSize: '1rem', width: 200 }} />
        <Skeleton
          variant={'rounded'}
          width={200}
          height={150}
          className={'mt-2'}
        />
      </div>
    </Grid>
  )
}
