import { useState } from 'react'

import { Card, Grid2 as Grid } from '@mui/material'
import { FLAG_FEATURES } from 'api/src/common/enums'
import type { EventTypeFilter } from 'types/graphql'

import { Metadata } from '@redwoodjs/web'

import { AuditLogTable } from 'src/components/AuditLog/AuditLogTable/AuditLogTable'
import HeaderOptions from 'src/components/AuditLog/HeaderOptions'
import FeatureDenied from 'src/components/Library/FeatureDenied/FeatureDenied'
import PageHeader from 'src/components/PageHeader/PageHeader'
import FeatureToggle from 'src/lib/hooks/LiveFeatureFlag/FeatureToggle'

const AuditLogPage = () => {
  const [query, setQuery] = useState<string>()
  const [isLiveFeed, setIsLiveFeed] = useState<boolean>(false)
  const [startDate, setStartDate] = useState<string>(undefined)
  const [endDate, setEndDate] = useState<string>(undefined)
  const [userIds, setUserIds] = useState<number[]>([])
  const [eventTypes, setEventTypes] = useState<EventTypeFilter[]>([])
  const [error, setError] = useState<string>()

  return (
    <>
      <Metadata title="Audit Logs" description="Description..." />
      <PageHeader title="Audit Logs" parentDataTestId="audit-log-page" />
      <FeatureToggle
        feature={FLAG_FEATURES.AUDIT_LOGS}
        InactiveComponent={<FeatureDenied />}
      >
        <Grid className={'p-5'}>
          <HeaderOptions
            isLiveFeed={isLiveFeed}
            onLiveFeedChange={setIsLiveFeed}
            query={query}
            setQuery={setQuery}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            userIds={userIds}
            eventTypes={eventTypes}
            error={error}
          />
          <Card
            className={'mt-5'}
            data-intercom-target={'audit-log-table-card'}
          >
            <AuditLogTable
              setUserIds={setUserIds}
              setEventTypes={setEventTypes}
              isLiveFeed={isLiveFeed}
              variables={{
                query,
                from: startDate,
                to: endDate,
                userIds,
                eventTypes,
              }}
              setError={setError}
            />
          </Card>
        </Grid>
      </FeatureToggle>
    </>
  )
}

export default AuditLogPage
