import React from 'react'

import { BoltIcon, CircleStackIcon } from '@heroicons/react/24/outline'
import { Grid2 as Grid, Typography } from '@mui/material'
import type { GetAuditLogsQuery } from 'types/graphql'

import UserAvatar from 'src/components/UserAvatar/UserAvatar'

export type AuditLog_User =
  GetAuditLogsQuery['auditLogs']['nodes'][number]['OriginDetails']['User']

interface Props {
  OriginDetails: GetAuditLogsQuery['auditLogs']['nodes'][number]['OriginDetails']
}

export default function UserRenderCell({
  OriginDetails: { User, generatedByDatabaseOperation, generatedByAutomation },
}: Props) {
  if (generatedByDatabaseOperation) {
    return (
      <Grid container alignItems={'center'} spacing={1}>
        <Grid>
          <CircleStackIcon className={'ml-1 mr-2 h-5 w-5 text-gray-500'} />
        </Grid>
        <Grid>
          <Typography variant={'overline'}>{'Database Operation'}</Typography>
        </Grid>
      </Grid>
    )
  }

  if (generatedByAutomation)
    return (
      <Grid container alignItems={'center'} spacing={1}>
        <Grid>
          <BoltIcon className={'ml-1 mr-2 h-5 w-5 text-gray-500'} />
        </Grid>
        <Grid>
          <Typography variant={'overline'}>{'Automation'}</Typography>
        </Grid>
      </Grid>
    )

  if (!User) return <Typography variant={'overline'}>{'-'}</Typography>

  return (
    <Grid container alignItems={'center'} spacing={1}>
      <Grid>
        <UserAvatar src={User.avatarUrl} height={8} />
      </Grid>
      <Grid>
        <Typography variant={'body2'}>{User.name}</Typography>
      </Grid>
    </Grid>
  )
}
