import React, { useState } from 'react'

import CodeIcon from '@mui/icons-material/Code'
import { Link, Tooltip } from '@mui/material'
import { Descriptions, Typography as AntDTypography } from 'antd'
import dayjs from 'dayjs'

import JSONViewerModal from 'src/components/AuditLog/JSONViewerModal'

export interface ListItem {
  label: string
  value: string
  isValueHighlighted?: boolean
}

interface Props {
  title: string
  listItems: ListItem[]
}

const { Paragraph } = AntDTypography

export default function ExpandedRowList({
  title,
  listItems,
}: Props): React.ReactElement {
  const [modalData, setModalData] = useState<{
    value: any
    label: string
  } | null>(null)

  const renderValue = (value: any, label: string) => {
    const EMPTY = ''
    const dateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{1,3}$/

    if (
      listItems.filter((item) => item.value === '').length === listItems.length
    )
      return EMPTY

    if (value === null || value === '') return EMPTY

    // If value is an array or object, display a link to view the data
    if (Array.isArray(value) || typeof value === 'object') {
      return (
        <>
          <Tooltip
            title={
              JSON.stringify(value, null, 2).length > 200 // truncate if large JSON value
                ? `${JSON.stringify(value, null, 2).substring(0, 200)}...`
                : JSON.stringify(value, null, 2)
            }
          >
            <Link
              underline={'none'}
              onClick={() => setModalData({ value, label })}
              className={'flex items-center'}
            >
              <CodeIcon className={'mr-1.5 text-[14px]'} />
              {'View Data'}
            </Link>
          </Tooltip>
        </>
      )
    }

    // Check if the value is a date string and format it
    if (typeof value === 'string' && dateRegex.test(value)) {
      return dayjs.utc(value).tz(dayjs.tz.guess()).format('Do MMM YYYY h:mm A')
    }

    return value?.toString()
  }

  return (
    <>
      <Descriptions
        title={title}
        bordered
        column={1}
        layout={'horizontal'}
        size={'small'}
      >
        {listItems.map((item) => (
          <Descriptions.Item
            key={item.label}
            label={
              item.label === 'Primary Field:' ? 'Primary Field' : item.label
            }
            className={item.isValueHighlighted ? 'bg-yellow-100' : ''}
            labelStyle={{
              fontWeight: item.label === 'Primary Field:' ? 700 : 0,
            }}
            contentStyle={{
              fontWeight: item.label === 'Primary Field:' ? 700 : 0,
              background:
                item.label === 'Primary Field:' ? 'rgba(0, 0, 0, 0.02)' : '',
            }}
          >
            <Paragraph
              ellipsis={{ expandable: true }}
              className="mb-0 min-w-[100px] max-w-[500px] font-sans"
            >
              {renderValue(item.value, item.label)}
            </Paragraph>
          </Descriptions.Item>
        ))}
      </Descriptions>
      {modalData && (
        <JSONViewerModal
          isOpen={true}
          onClose={() => setModalData(null)}
          JSON={JSON.stringify(modalData.value, null, 2)}
          fieldKey={modalData.label}
        />
      )}
    </>
  )
}
