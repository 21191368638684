import React from 'react'

import { Typography } from '@mui/material'
import hljs from 'highlight.js'

import Modal from 'src/components/Modal/Modal'

interface Props {
  isOpen: boolean
  onClose: () => void
  JSON: string
  fieldKey: string
}

export default function JSONViewerModal({
  isOpen,
  onClose,
  JSON,
  fieldKey,
}: Props): React.ReactElement {
  return (
    <Modal
      title={'Audit Log Data Viewer'}
      open={isOpen}
      onClose={onClose}
      footerVisible
      confirmText={'Close'}
      onCancel={onClose}
      onConfirm={onClose}
    >
      <div className={'m-5'}>
        <Typography variant={'h6'} className={'mb-4'}>
          {fieldKey}
        </Typography>
        <pre
          className={
            '!text-[14px] !font-inherit bg-[#F6F8FA] p-5 rounded overflow-scroll'
          }
        >
          <code
            className={'!font-inherit'}
            dangerouslySetInnerHTML={{
              __html: hljs.highlight('json', JSON).value,
            }}
          />
        </pre>
      </div>
    </Modal>
  )
}
