// This query populates the dropdown for the Event Type filter in the Audit Log table.
export const AUDIT_LOG_EVENT_TYPES_QUERY = gql`
  query AuditLogEventTypesQuery {
    auditLogEventTypes {
      id
      name
      source
      baserowShortDescr
      hubsActionType
      hubsTableName
    }
  }
`
