import React from 'react'

import EastIcon from '@mui/icons-material/East'
import { DatePicker } from 'antd'
import { RangePickerProps } from 'antd/es/date-picker'
import dayjs from 'dayjs'

const { RangePicker } = DatePicker

interface Props extends RangePickerProps {
  startDate?: string
  endDate?: string
  setStartDate: (date: string | undefined) => void
  setEndDate: (date: string | undefined) => void
  onChange?: RangePickerProps['onChange']
}

export default function DateRangePicker({
  startDate,
  endDate,
  setEndDate,
  setStartDate,
  onChange,
  ...props
}: Props): React.ReactElement {
  return (
    <RangePicker
      separator={<EastIcon className={'-ml-2 mr-2 text-[18px]'} />}
      format={'DD MMM. YYYY'}
      allowEmpty={[true, true]}
      value={[
        startDate ? dayjs(startDate) : null,
        endDate ? dayjs(endDate) : null,
      ]}
      onChange={(value, ...rest) => {
        setStartDate(value?.[0]?.format('YYYY-MM-DD') || undefined)
        setEndDate(value?.[1]?.format('YYYY-MM-DD') || undefined)
        onChange?.(value, ...rest)
      }}
      data-intercom-target="date-range-picker"
      data-testid="date-range-picker"
      {...props}
    />
  )
}
